body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.espanel {
  border: solid 1px #ccc;
  border-radius: 10px;
  padding: 10px;
  margin: 10px
}

.student-form-input {
  outline: none;
  width: 100%;
  display: block;
  border: none;
  border-bottom: 2px solid #ebebeb;
  padding: 5px 0;
  color: #222;
  margin-bottom: 31px;
  font-family: roboto slab;
}

#input {
  outline: none;
  border: none;
  background-color: #e6ebf5;
  border-radius: 5px;
  width: 100%;
  margin-right: 10px;
  padding: 10px;
  font-size: 17px;
}


.side-nav {
  min-width: 70px;
  max-width: 70px;
  height: 100vh;
  min-height: 570px;
  background-color: #fff;
  display: flex;
  z-index: 9;
  box-shadow: 0 2px 4px rgb(15 34 58 / 12%);
  display: flex;
  flex-direction: column;
  padding: 10px 0px 15px 0px;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
}

.side-nav div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.mid-icon {
  height: 60%;
  color: #6f7178;
}

.bottom-icon {
  height: 10%;
  color: #6f7178;
}

.side-nav svg {
  cursor: pointer;
  font-size: 1.7rem;
}

.home-cont {
  display: flex;
  justify-content: space-around;
}

.mychat-cont {
  color: #495057;
  width: 26%;
  height: 100vh;
  min-width: 270px;
  background-color: #f5f7fb;
}

.search-cont {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e6ebf5;
  padding: 5px;
  width: 87%;
  margin: auto;
  border-radius: 5px;
  margin-top: 10px;
}

.search-cont svg {
  margin: 0 12px 0 12px;
}

.search-cont input {
  outline: none;
  border: none;
  background-color: inherit;
  width: 87%;
  padding: 10px;
  font-size: 17px;
}

.notification {
  display: flex;
  align-items: center;
  margin: auto;
  justify-content: space-between;
  width: 80%;
}

.notification h2 {
  color: #495057;
  font-family: "Public Sans", sans-serif;
  font-weight: 600;
}

.recent-chat {
  width: 100%;
  margin: auto;
}

.Recent {
  color: #495057;
  font-family: "Public Sans", sans-serif;
  font-weight: 600;
  margin-left: 20px;
}

.recent-user {
  /* border: 2px solid red; */
  overflow-y: scroll;
  box-sizing: border-box;
  height: 75vh;
}

.recent-user::-webkit-scrollbar {
  width: 10px;
}

/* Handle */
.recent-user::-webkit-scrollbar-thumb {
  background: #c4ccdbb2;
  border-radius: 10px;
}

.user {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  height: 3.4rem;
  /* margin: 20px; */
  margin-top: 0px;
  padding: 11px 13px;
  margin: 0px 12px;
}

.user:hover {
  border-radius: 7px;
  background-color: #e6ebf5;
}

.selectUser {
  border-radius: 7px;
  background-color: #e6ebf5;
}

.history-cont {
  display: flex;
  align-items: center;
}

.name {
  color: #495057;
  font-family: "Public Sans", sans-serif;
  font-weight: 600;
  margin-bottom: 0px;
  margin-left: 14px;
}

.chat {
  margin-top: 0px;
  margin-left: 14px;
  font-size: 15px;
  color: #7a7f9a;
}

.time {
  font-size: 12px;
}

.unseen-chat {
  font-size: 14px;
  color: #ef476f;
  background-color: rgba(239, 71, 111, 0.18);
  border-radius: 50%;
  width: 20px;
  text-align: center;
}

.chattingpage {
  background-color: white;
  width: 70%;
  box-shadow: 0 2px 4px rgb(15 34 58 / 12%);
  height: 100vh;
}

@media screen and (max-width: 800px) {
  .chattingpage {
    width: 100%;
  }
}

.top-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  border-bottom: 1px solid #f0eff5;
}

.user-header {
  display: flex;
  align-items: center;
}

.user-name {
  color: #495057;
  font-family: "Public Sans", sans-serif;
  font-weight: 600;
  margin-left: 15px;
}

.user-fet {
  color: #6f7178;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  margin-right: 20px;
}

.live-chat {
  /* border: 2px solid red; */
  padding: 20px;
  max-height: 77%;
  height: 72%;
  box-sizing: border-box;
  overflow-y: hidden;
  overflow: scroll;
}

.live-chat::-webkit-scrollbar {
  width: 10px;
}

/* Handle */
.live-chat::-webkit-scrollbar-thumb {
  background: #c4ccdbb2;
  border-radius: 10px;
}

.sender-cont {
  display: flex;
  justify-content: space-around;
  /* border-top: 1px solid #f0eff5; */
  /* align-items: center; */
  padding: 15px;
}

.react-input-emoji--container {
  border-radius: 0px !important;
  background: none !important;
  overflow: auto;
}

.send-message {
  max-height: 36px;
  margin-top: 0px;
}

.react-input-emoji--input {
  max-height: 32px !important;
  box-sizing: border-box !important;
  overflow-y: auto !important;
}

.react-input-emoji--input::-webkit-scrollbar {
  display: none;
}

.leftuser-chat {
  display: flex;
  flex-direction: row-reverse;
}

.rihgtuser-chat {
  display: flex;
}

.right-avt {
  /* border: 2px solid red; */
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: right;
  max-width: 80%;
  color: #fff;
}

.left-avt {
  /* border: 2px solid black; */
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: flex-end;
  gap: 10px;
  max-width: 80%;
}

.left-msg {
  background-color: #7269ef;
  padding: 8px;
  border-radius: 10px;
  margin: 3px;

}

.right-msg {
  background-color: #f5f7fb;
  padding: 8px;
  border-radius: 10px;
  margin: 3px;
}

.left-msg p {
  margin: 0px;
  color: white;
}

.right-msg p {
  margin: 0px;
}

.blank-div {
  margin: 20px;
}

.start-msg {
  display: flex;
  align-items: center;
  justify-content: center;
  /* position: absolute; */
}

.start-msg div {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.start-msg p {
  color: #7a7f9a;
  margin: 0px;
}

.group-people-cont {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.group-people {
  display: flex;
  align-items: center;
  gap: 10px;
}

svg {
  cursor: pointer;
}

.tox-notifications-container {
  display: none !important;
}

.modal-90w {
  max-width: 95% !important;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 10px solid #f3f3f3;
  /* Light grey */
  border-top: 10px solid #383636;
  /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

.loading-div {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-25px, -25px);
}

.spinner-container {
  top: 0;
  left: 0;
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: #6f717831;
  z-index: 10;
}

.tabulator-cell {
  white-space: normal !important;
  height: auto !important;
}


.custom-alert-bg {
  display: none;
  height: 100%;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background: #00000060;
  z-index: 1;
  align-items: flex-start;
  justify-content: center;
}

.custom-alert {
  background-color: rgb(255, 255, 255);
  border-radius: 5px;
  border: 1px solid;
  padding: 15px;
  box-shadow: 0px 0px 4px 1px;
  margin-top: 20px;
}

.custom-alert .title {
  font-size: 20px;
  margin-bottom: 5px;
}

.custom-alert .message {
  font-size: 16px;
  margin-block: 10px;
}

.quiz-question table,
.quiz-question th,
.quiz-question td {
  border: 1px solid !important;
  border-collapse: collapse !important;
}


@page {
  size: A4;
  margin: 0.7in;
  margin-top: 0.6in;


  @top-center {
    font-family: sans-serif;
    font-weight: bold;
    font-size: 2em;
    content: counter(page);
  }
}


:root {
  --printHeaderHeight: 12px;
}

@media print {


  .print-col-container {
    column-count: 2;
    column-rule: 1px solid #000;
    width: 200mm;
  }

  .print-fw-container {
    width: 200mm;
    border-top: "1px solid";
    border-bottom: "1px solid";
    line-height: "18px";
  }
}

.que-viewer img {
  height: auto;
}



.question {
  display: flex;
  padding-bottom: 20px;
  align-items: baseline;
  font-size: 16px;
}

table.report-container {
  page-break-after: always;
  /* padding: 3px; */
  margin: auto;
  font-family: 'Times New Roman', Times, serif;

}

.single-question {
  line-height: 18px;
}

thead.report-header {
  display: table-header-group;
  /* border-bottom: 1px solid #000; */
}

tfoot.report-footer {
  display: table-footer-group;
  border-top: 1px solid #000;
}

.header-info {
  display: flex;
  align-items: center;
  transform: translate(-1px, 7px);
}

.tbody-header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #000;
  font-size: 22px;
  font-style: italic;
  font-family: auto;
  font-weight: bold;
  transform: translateY(calc(var(--printHeaderHeight)*-1));
}

.dummyHeader {
  display: flex;
  justify-content: center;
  height: var(--printHeaderHeight);
  background-color: #fff;

}

.headerDevider {
  /* column-count: 2; */
  /* column-rule: 1px solid red; */
  border-right: 1px solid #000;
  height: var(--printHeaderHeight);
  position: fixed;
}


.question img {
  max-width: 100%;
}

.module-options {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  font-size: 16px;
  line-height: 18px;
}

.option {
  display: flex;
  flex: 1;
  min-width: fit-content;
  max-width: 100%;
  margin: 5px 5px 0 0;
  /* align-items: baseline;
  margin-top: 5px;
  flex: 1 0 25%;
  /* min-width: max-content; */
}

.question p {
  margin-bottom: 0;
  text-align: justify;
  break-inside: avoid;
}

.mjx-surd {
  vertical-align: middle !important;
  margin-right: 0% !important;
  margin-top: -2% !important;
}

.MJXc-TeX-main-R,
.MJXc-TeX-math-I {
  font-family: "Times new Roman" !important;
}

.mjx-surd {
  vertical-align: middle !important;
}

.MJXc-display {
  display: inline !important;
}

.mjx-over {
  height: 0.4em !important;
}



/* enrollments on hover cursor add  */
.sc-jrcTuL:hover {
  cursor: pointer;
  /* box-shadow: 1px -2px 6px 2px black; */
}

.enrollment-hypterlink {
  text-decoration: none;
  border-bottom: 1px solid transparent;
  transition: border-bottom 0.3s ease;
  color: #0d6efd
}

.enrollment-hypterlink:hover {
  border-bottom: 1px solid;
}


/* doubuts  */
.ticket-id {
  background-color: #5F46E3;
  font-size: 18px;
  font-weight: bold;
  width: 40px;
  height: 40px;
  /* padding: 8px; */
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}


.unread-message {
  border-radius: 20px;
  background-color: #0066ff;
  padding: 3px;
  font-weight: bold;
  font-size: 10px;
  position: absolute;
  z-index: 10;
  top: 0;
  left: 40px;
  width: 20px;
  color: white;
}

.bradcrame-inactive {
  text-decoration: none;
  color: black;
}

.tox {
  display: none;
}



@keyframes slide-left {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }
}

@keyframes slide-up {
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(0);
  }
}




.backlog-card {
  background: #F3F3F4;
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 20px;
  position: relative;
  transition: transform 0.5s ease;
  box-shadow: 0px 1px 1px 1px #d8d8d8;
}


.backlog-card-inner {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  /* position: relative; */
}

.slide-out {
  animation: slide-left 0.5s forwards;
}

.slide-up {
  animation: slide-up 0.5s forwards;
  /* z-index: -1; */
}


.flex-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
}

.backlog-header {
  background-color: #FFF;
  padding: 10px;
  text-align: center;
  position: fixed;
  box-shadow: 0px 1px 10px 1px rgba(0, 0, 0, 0.1);
  top: 0;
  width: 100%;
  height: 70px;
  align-content: center;
  z-index: 1000;
}

.backlog-footer {
  background-color: #FFF;
  padding: 10px;
  text-align: center;
  align-content: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 70px;
  left: 0;
  right: 0;
  z-index: 1000;
  box-shadow: 0px -2px 10px 2px rgba(0, 0, 0, 0.1);
}

.backlog-main {
  flex: 1;
  margin-top: 70px;
  overflow-y: auto;
  margin-bottom: 70px;
  padding: 20px;
  padding-bottom: 70px;
  text-align: -webkit-center;
  height: 85vh;
}


.backlog-indicator-points {

  /* display: block; */
  height: 200%;
  aspect-ratio: 1;
  position: absolute;
  left: 0;
  top: -5px;
  border-radius: 50%;
  background: #000;
  transform: translateX(-50%);
}

.point-text {
  position: absolute;
  left: -100%;
  top: 100%;
  text-wrap: nowrap;
}
.backlog-slider {
  width: 60%;
  margin: 50px auto;
  -webkit-appearance: none;
  height: 8px;
  border-radius: 4px;
  margin-bottom: 15px;
  background-color: rgb(200, 200, 200);
}

.backlog-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 18px;
  height: 18px;
  border-radius: 10px;
  background-color: rgb(255, 120, 0);
  overflow: visible;
  cursor: pointer;
}

.backlog-indicator {
  border: 1px solid;
  padding: 20px 0px 60px 0px;
  border-radius: 20px;
  margin-bottom: 30px;
}

.indicator-points {

  /* display: block; */
  height: 200%;
  aspect-ratio: 1;
  position: absolute;
  left: 0;
  top: -5px;
  border-radius: 50%;
  background: #000;
  transform: translateX(-50%);
}



/* CustomSlider.css */

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 10px;
  background: #d3d3d3;
  outline: none;
  transition: opacity 0.2s;
  border-radius: 5px;
  margin-left: 20px;
}

.slider-container {
  display: flex;
  align-items: baseline;
  justify-content: space-around;
}

.slider:hover {
  opacity: 1;
}

.thumb-value {
  width: 5rem;
  margin-left: 10px;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 30px;
  height: 30px;
  background: var(--thumb-image) no-repeat;
  background-size: cover;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 30px;
  height: 30px;
  background: var(--thumb-image) no-repeat;
  background-size: cover;
  cursor: pointer;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.fade-in {
  animation: fadeIn 2s forwards;
}

.fade-out {
  animation: fadeOut 2s forwards;
}

.backlog-chapter-tab li button {
  padding-inline: 0;
}

.nav-item{
  white-space: nowrap;
  text-overflow: ellipsis;
}
.custom-check{
  padding-left: 0.125rem;
}

.submit-button-container {
  flex-shrink: 0;
  width: 100%;
  position: fixed;
  display: flex;
  justify-content: center;
  bottom: 0;
  background-color: #FFF;
  z-index: 9;
  box-shadow: 0px 1px 10px 1px rgba(0, 0, 0, 0.1);
  padding: 5px;
  text-align: right;
  transition: bottom 0.3s;
}
