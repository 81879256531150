#tab-tab-Upcoming,
#tab-tab-All {
  max-width: 200px !important;
  margin: auto;
}

.nav-link,
.nav-link:focus,
.nav-link:hover {
  color: var(--text-color) !important;
}

.nav-link.active {
  color: var(--primary-color) !important;
}


.nav-link.active {
  color: var(--primary-color) !important;
}

.live-now-series {
  color: #F24E1E;
  font-family: Lato;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0em;
}

.payment-successful {
  color: #0D1015;
  font-family: Lato;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
}


.coursal-indicator {
  background-color: #CDCFD3;
  height: 6px;
  width: 6px;
  border-radius: 50%;
}

.coursal-indicator-active {
  Background-Color: #0D1015;
  height: 7px;
  width: 7px;
  border-radius: 50%;
}


.login-coursal-heading {
  font-family: Lato;
  font-size: 32px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0px;
  color: #0D1015
}

.login-coursal-subheading {
  font-family: Lato;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0px;
  color: #0D1015
}



.swiper {
  width: 100%;
  height: 100%;
}

.warn {
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  color: #CC2C2C;
}

.MJXc-display {
  display: inline !important;
}


button#message-modal-class-1:hover {
  color: white !important;
  background-color: red !important;
  border-color: red !important;
}


button#message-modal-class-2:hover {
  color: black !important;
  background-color: white !important;
  border-color: white !important;
}


.scholarship-offer {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: #5F46E3; */
  color: #5F46E3;
  font-size: x-large;
  font-weight: bold;
}

@keyframes zoom {
  0% { font-size: 22px; }
  50% { font-size: 26px; } /* Adjust the size for zoom-in effect */
  100% { font-size: 22px; }
}

.zoom-text {
  text-align: center;
  font-size: 22px;
  font-weight: bold;
  height: 100px;
  /* animation: zoom 3s infinite alternate; Change the duration as needed */
}

.zoom-text:hover {
  color: blue; /* Change color on hover */
}

.chapter-selector {
  background-color: #f9f9f9; 
  border-radius: 4px; 
  border: 1px solid #ddd; 
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
}

.chapter-label {
  font-size: 1rem; 
  color: #333; 
  cursor: pointer; 
}

.chapter-selector:hover {
  background-color: #e9ecef; 
}



.chat-input-container {
  display: flex;
  align-items: center;
  position: fixed;
  bottom:0px;
  width: 100%;
  padding: 10px;
  background-color: #ffffff;
  /* border-top: 1px solid #ddd; */
  /* box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1); */
}

.input-container {
  flex: 1;
}

.calendar-icon {
  position: absolute;
  left: 18px;
  top:22.5px;
  color: #5F46E3 ;
}

.send-icon {
  position: absolute;
  right: 20px;
  top:22.5px;
  color: #5F46E3;
  cursor: pointer;
}

.send-icon:hover {
  color: #1c8c75;
}
.txt-area {
    width: 100%;
    font-size: 14px;
    padding-left: 40px;
    padding-right: 40px;
    font-weight: 400;
    overflow-y: auto;
    resize: none;
    border-radius: 25px;
    border: 1px solid #ddd;
    outline: none;
    padding: 13px;
    padding-left: 40px;
    padding-right: 35px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    background-color: #fff;
    -ms-overflow-style: none; 
    scrollbar-width: none;
}
.txt-area::-webkit-scrollbar {
  display: none;
}